.logo {
  animation: logo-spin infinite 5s linear;
  height: 10vmin;
  pointer-events: none;
  transform-origin: 50% 50%;
}

.loading {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@keyframes logo-spin {
  0% {
    opacity: 0;
    transform: rotate(0deg);
  }
  20% {
    opacity: 0;
    margin-top: 0px;
  }
  22.5% {
    opacity: 0;
    margin-top: 10px;
  }
  25% {
    opacity: 1;
    margin-top: 0px;
  }
  70% {
    margin-top: 0px;
  }
  72.5% {
    margin-top: 10px;
  }
  75% {
    margin-top: 0px;
  }
  90% {
    margin-top: 0px;
    opacity: 1;
    transform: rotate(0deg);
  }
  100% {
    margin-top: 10px;
    opacity: 0;
    transform: rotate(360deg);
  }
}
