@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* html {
  font-size: 62.5%;
} */

body {
  margin: 0;
  padding: 48px 0 0;
  font-weight: 400;
  font-family:'Merriweather Sans',sans-serif;color:#4c5257;background-color:#f5f7fa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5rem;
}
@media only screen and (max-width: 768px) { 
  body {
    padding: 42px 0 0; 
  }
}

h1,h2,h3,h4,h5,h6{font-family:Merriweather,serif;color:#054d85;line-height: 1.2em;}
h1,h2,h3,h4,h5,h6{font-weight: 500;}

h1 {
  font-size: 2em; 
}
@media only screen and (max-width: 768px) { 
  h1 {
    font-size: 1.5em; 
  }
}

@media only screen and (max-width: 992px) {
  h2 {
    font-size: 1.2em; 
  }
}

a {color:#054d85;}

.slick-prev, .slick-next {
  font-size: 15px !important;
}
.slick-prev:before, .slick-next:before  {
  content: '' !important;
}
#howItWorks .slick-dots {
  width: 33.3% !important;
  right: 0px !important;
}

.sm-font {
  font-family: 'Merriweather Sans',sans-serif;
  font-size: .55em;
  text-transform: uppercase;
  letter-spacing: .2em;
  font-weight: 300;
}

ol, ul {font-size: .875em;}

.highlight {
  position: relative;
  display: inline-block;
  z-index: 1;
  font-weight: 700;
}
.highlight::after {
  content: '';
  left: -1.25%;
  position: absolute;
  width: 102.5%;
  height: 35%;
  top: 50%;
  transform: translateY(-36%);
  background-color: #ccefc4;
  z-index: -1;
}

.quote {
  position: relative;
  padding: 0rem 3rem;
  line-height: 1.8rem;
  font-weight: 400;
  font-size: 1.25rem;
  text-align: left;
}
.quote::before {
  content: '❝';
  top: 0rem;
  left: .125rem;
  position: absolute;
  font-size: 8rem;
  color: #ccefc4;
  z-index: -1;
}
/* .quote::after {
  content: '❞';
  bottom: -1.5rem;
  right: 1.5rem;
  position: absolute;
  font-size: 8rem;
  color: #ccefc4;
  z-index: -1;
} */
@media only screen and (max-width: 768px) { 
  .quote {
    text-align: center;
    font-size: 1rem;
    padding: 0 1rem;
    margin-top: 2rem;
  }
  .quote::before {
    font-size: 5rem;
  }
}

.step_1 {
  background: url("../imgs/step1.png") no-repeat top center;
  background-size: 40px 74px;
  height: 74px;
  padding-top: 15px;
  margin: 0px;
}
.step_2 {
  background: url("../imgs/step2.png") no-repeat top center;
  background-size: 40px 74px;
  height: 74px;
  padding-top: 15px;
  margin: 0px;
}
.step_3 {
  background: url("../imgs/step3.png") no-repeat top center;
  background-size: 40px 74px;
  height: 74px;
  padding-top: 15px;
  margin: 0px;
}
.step_4 {
  background: url("../imgs/step4.png") no-repeat top center;
  background-size: 40px 74px;
  height: 74px;
  padding-top: 15px;
  margin: 0px;
}

.autocomplete-dropdown-container {
  /* width: calc(80% - 1rem);
  margin-left: 10%; */
  font-size: .9rem;
  border-radius: 5px;
  padding-bottom: 20px;
  background-size: auto 12px;
  background-position: bottom right 2rem;
  background-repeat: no-repeat;
  background-image:
    url('https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png');
}
.suggestion-item, .suggestion-item--active {
  padding: .25rem .5rem;
}
.suggestion-item--active {
  background-color: rgba(204, 239, 196, .35) !important;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}

#comparison {
  position: relative;
  background-image: linear-gradient(80deg, #e8f5e3 50%, #FFFFFF calc(50% + 2px));
  overflow: hidden;
}
@media only screen and (max-width : 960px) {
  #comparison {
      position: relative;
      background-image: linear-gradient(90deg, #e8f5e3 50%, #FFFFFF calc(50% + 2px));
      overflow: hidden;
  }
}

#comparison .dark-green h3 {
  color: #47623f;
}

#comparison .table td, #comparison .table th {
  border: 0px;
  padding: .5rem;
  width: 50%;
}

#comparison .table th {
  text-align: center;
}